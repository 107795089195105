@font-face {font-family: 'Raleway';
  font-weight: 300;
  src: url('./fonts/webfonts/Raleway/Raleway-Light.ttf') format('truetype');}
@font-face {font-family: 'Raleway';
  font-weight: 500;
  src: url('./fonts/webfonts/Raleway/Raleway-Medium.ttf') format('truetype');}
@font-face {font-family: 'Raleway';
  font-weight: 600;
  src: url('./fonts/webfonts/Raleway/Raleway-SemiBold.ttf') format('truetype');}
@font-face {font-family: 'Raleway';
  font-weight: 700;
  src: url('./fonts/webfonts/Raleway/Raleway-Bold.ttf') format('truetype');}
