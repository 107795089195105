.section-search {
  background-image: none;
  background-color: $color-104;
}

.section-search .search-content .clearfix,
.section-search .search-content .scope-select .bootstrap-select button {
  box-shadow: none;
}

button.accent, input[type=submit].accent, input[type=button].accent {
  font-weight: 500;
}

#editorial {
  main {
    .container {
      article {
        .main-article {
          section {
            &.section-tiles {
              ul {
                li {
                  figure {
                    &.tile-classificationScheme {
                      background-image: url(./img/classification-scheme.jpg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Issue Expo : https://github.com/naoned/expo/issues/6910
main {
  .container {
    button.btn-primary {
      a:not(.btn):not(.actionbar-action) {
        text-decoration: none;
      }
    }
    a:not(.btn):not(.actionbar-action) {
      text-decoration: underline;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
}

#notice-complete, #finding-aid-archdesc {
  main {
    .container {
      .notice {
        .list-notice {
          #classification-plan-navigation {
            ul {
              li {
                a {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

#classification-browsing {
  #classification-browser {
    #classification-tree {
      li {
        a {
          text-decoration: none;
        }
      }
    }
    #breadcrumb {
      > .breadcrumb-level:not(:first-child):before {
        display:inline-block;
      }
    }
  }
}

#user-profile {
  main {
    .container {
      .user-profile-menu {
        ul {
          li {
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

#user-profile {
  main {
    .container {
      .user-profile-content {
        table {
          td.actions, th.actions {
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

#display-results {
  .container {
    #list-container.chrono-container {
      .element-list #chrono {
        .tl-storyslider {
          .tl-slide {
            .tl-slide-scrollable-container {
              .tl-text {
                a {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

.section-search {
  .search-content {
    .scope-select {
      .bootstrap-select {
        div.dropdown-menu {
          ul {
            li {
              a {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
